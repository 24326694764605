(function() {
	'use strict';
	var $document = $(document),
		$window = $(window),
		$htmlBody = $('html, body');

// Cookie consent
$(document).ready(function() {
	window.cookieconsent.initialise({
		'palette': {
			'popup': {
				'background': '#303030'
			},
			'button': {
				'background': '#fadc55'
			}
		},
		'theme': 'edgeless',
		'position': 'bottom-right',
		'content': {
			'message': 'Na našich stránkach používame technické cookies, ktoré služia na zaistenie základných funkcií týchto stránok.',
			'dismiss': 'OK',
			'link': 'Viac',
			'href': '/cookies'
		}
	});
});
// /Cookie consent

// Cookie module
	// https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
	window.createCookie = function(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + value + expires + '; path=/';
	};

	window.readCookie = function(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1, c.length);
			}

			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length, c.length);
			}
		}
		return null;
	};

	window.eraseCookie = function(name) {
		window.createCookie(name, '', -1);
	};
// /Cookie module

// Make ID module
	window.makeid = function(length) {
		var text = '';
		var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (var i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	};
// /Make ID module

// Slider
	(function() {
		var $slider = $('.js-slider');
		$slider.each(function() {
			var $self = $(this),
				$items = $self.find('.js-item'),
				$itemsLength = $self.find('.js-item').length,
				$controls = $self.find('.js-controls'),
				$arrowLeft = $self.find('.js-arrow-left'),
				$arrowRight = $self.find('.js-arrow-right'),
				$bullets,
				$bullet;

			$controls.append('<ul class="js-bullets bullets"></ul>');
			$bullets = $controls.find('.js-bullets');

			for (var i = 0; i < $itemsLength; i++) {
				var str = '<li class="js-bullet bullet"></li>';
				$bullets.append(str);
			}

			$bullet = $bullets.find('.js-bullet');

			$bullet.on('click', function() {
				var index = $bullet.index(this);
				$items.removeClass('active');
				$bullet.removeClass('active');
				$items.eq(index).addClass('active');
				$bullet.eq(index).addClass('active');
			});


			$items.eq(0).addClass('active');
			$bullet.eq(0).addClass('active');

			function slide(direction) {
				var index = $items.index($self.find('.js-item.active'));

				$items.removeClass('active');
				$bullet.removeClass('active');

				if (direction === 'prev') {
					if (index !== 0) {
						$items.eq(index - 1).addClass('active');
						$bullet.eq(index - 1).addClass('active');
					} else {
						$items.last().addClass('active');
						$bullet.last().addClass('active');
					}
				}

				if (direction === 'next') {
					if (index < $itemsLength - 1) {
						$items.eq(index + 1).addClass('active');
						$bullet.eq(index + 1).addClass('active');
					} else {
						$items.first().addClass('active');
						$bullet.first().addClass('active');
					}
				}
			}

			$arrowLeft.on('click', function() {
				slide('prev');
			});

			$arrowRight.on('click', function() {
				slide('next');
			});

			$self.swipe({
				// Generic swipe handler for all directions
				swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
					if (direction === 'left') {
						slide('next');
					} else if (direction === 'right') {
						slide('prev');
					}
				}
			});
		});
	})();
// /Slider

// Scroll to anchor
function scrollToAnchor(id){
	// console.log(id);
	var aTag = $('[id="' + id + '"]');

	$htmlBody.animate({scrollTop: aTag.offset().top}, 'slow');
}
// /Scroll to anchor


// Sticky bar Produkty
function waypoints($element, offsetTop, offsetBottom) {
	'use strict';
	var elementTop = $element.offset().top,
		elementBottom = elementTop + $element.outerHeight(),
		viewportTop = $(window).scrollTop(),
		viewportBottom = viewportTop + $(window).height(),
		offsetTop = offsetTop ? offsetTop : 0,
		offsetBottom = offsetBottom ? offsetBottom : 0;

	if (offsetTop < 1 && offsetTop !== 0) {
		offsetTop = $element.outerHeight() * offsetTop;
	}

	if (offsetBottom < 1 && offsetBottom !== 0) {
		offsetBottom = $element.outerHeight() * offsetBottom;
	}

	return elementBottom - offsetBottom > viewportTop && elementTop + offsetTop < viewportBottom;
}

if (window.location.pathname.indexOf('/produkty') > -1) {
	var $menuProducts = $('.js-menu-products'),
		$menuProductsLinks = $menuProducts.find('a:not(.js-header-cart-toggle)');

	$menuProductsLinks.on('click', function(event) {
		event.preventDefault();
		var id = $(this).attr('href').substring(1);

		scrollToAnchor(id);
	});



	// $menuProducts


	// waypoints($('#features')); // #features is in viewport => true or false
	
	// // Offset top usage
	// waypoints($('#features'), 100); // scrolling from top of the item - item considered in VP when 100px inside of the item from its top

	// waypoints($('#features'), 0.5); // scrolling from top of the item - item considered in VP when 50% inside of the item from its top

	// // Offset bottom usage
	// waypoints($('#features'), undefined, 200); // scrolling from bottom of the item - item considered in VP when 200px inside of the item from its bottom

	// // Both offsets usage
	// waypoints($('#features'), 150, 200); // 150px 200px
	// waypoints($('#features'), 0.15, 0.2); // 15% 20%
	// waypoints($('#features'), 0.15, 200); // 15% 200px

	// // Scroll/resize usage
	// $(window).on('resize scroll', function() {
	// 	if (waypoints($('#features'))) {
	// 		// console.log('#features is in viewport');
	// 	}
	// });









	// grab the initial top offset of the navigation 
	var offset = $menuProducts.offset().top;
	
	// our function that decides weather the navigation bar should have "fixed" css position or not.
	function stickyNav() {
		var scrollTop = $(window).scrollTop(); // our current vertical position from the top
			
		// if we've scrolled more than the navigation, change its position to fixed to stick to top,
		// otherwise change it back to relative
		if (scrollTop > offset) { 
			$menuProducts.addClass('sticky');
		} else {
			$menuProducts.removeClass('sticky'); 
		}
	}

	stickyNav();
	// and run it again every time you scroll
	$window.on('scroll', function() {
		stickyNav();
	});
	
	
}
// /Sticky bar Produkty

// Disable buttons on out of stock products
if (window.location.pathname.indexOf('/produkty') > -1) {
	var $product = $('.wrap');

	$product.each(function() {
		var $self = $(this),
			$out = $self.find('.stock.out');

		if ($out.length > 0) {
			$self.addClass('out-of-stock');
		}
	});
}
// /Disable buttons on out of stock products

// Cart
	(function() {
		// Add to cart buttons
		var $addToCart = $('.js-add-to-cart');
		$addToCart.on('click', function() {
			var $self = $(this),
				buttonData = JSON.parse($self.attr('data')),
				productID = buttonData.id.toString(),
				userID = window.readCookie('vishnu-products'),
				cartStorage = getLocalstorage(true),
				arr = [];

			if (cartStorage[userID]) {
				for (var key in cartStorage[userID]) {
					arr.push(key);
				}

				if (arr.indexOf(buttonData.id) === -1) {
					cartStorage[userID].items[productID] = buttonData;
					// localStorage.setItem('vishnu-cart', JSON.stringify(cartStorage));
					updateLocalstorage(cartStorage);
					// console.log('here');
					updateHeaderCartTemplate();
					updateHeaderCartCounter();
				}
			}
		});
		// /Add to cart buttons

		// Cart template
		function updateHeaderCartTemplate() {
			var cartStorage = getLocalstorage(false).items,
				$headerCartItems = $('.js-header-cart-items');

			$headerCartItems.html('');
			$.each(cartStorage, function(key, element) {
				$headerCartItems.append('<div class="js-header-cart-item cart-item"><img class="thumb" src="' + element.link_image + '" alt=""><span class="name">' + element.name + '</span><span class="price">€' + element.price + '</span><span class="js-header-cart-item-remove cart-item-remove"></span></div>');
			});
		}
		// /Cart template

		// Cart counter
		function updateHeaderCartCounter() {
			var cartStorage = getLocalstorage(false).items,
				$headerCartItemsCounter = $('.js-header-cart-items-counter'),
				counter = 0;

			for (var key in cartStorage) {
				counter++;
			}

			$headerCartItemsCounter.text(counter);
		}
		// /Cart counter

		// Cart page items list
		function listCartItems(cartStorage, cookie) {
			var counter = 0,
				$notificationEmpty = $('.js-notification-empty'),
				$cartPageItems = $('.js-cart-page-items'),
				$cartPageOthers = $('.js-cart-page-others'),
				$totalPrice = $('.js-total-price'),
				$totalWeight = $('.js-total-weight'),
				totalPrice = 0,
				totalWeight = 0;

			$.each(cartStorage.items, function() {
				counter++;
			});

			if (counter === 0) {
				$notificationEmpty.addClass('show');
				$cartPageOthers.removeClass('show');
				$cartPageItems.removeClass('show');
				$totalPrice.text('€' + totalPrice);
				$totalWeight.text(totalWeight + 'g');
				$window.trigger('totalPriceChanged');
			} else {
				$notificationEmpty.removeClass('show');
				$cartPageOthers.addClass('show');
				$cartPageItems.addClass('show');

				$.each(cartStorage.items, function(key, element) {
					var amount = cartStorage.items[key].amount, 
						resultPrice = parseFloat(cartStorage.items[key].price) * amount;

						totalPrice += resultPrice;
						resultPrice = resultPrice.toString().indexOf('.') > -1 ? resultPrice.toString() + '0' : resultPrice;

					$cartPageItems.append('<div class="js-cart-page-item cart-page-item" data-id="' + element.id + '"><img class="thumb" src="' + element.link_image + '" alt=""><span class="name">' + element.name + '</span><span class="weight">' + element.weight + 'g</span><div class="item-counter"><div class="item-counter-wrap"><span class="js-item-counter-less item-counter-less toggler">-</span><span class="js-item-counter-amount item-counter-amount">' + amount + '</span><span class="js-item-counter-more item-counter-more toggler">+</span></div></div><span class="price">€' + element.price + '</span><span class="js-item-price-result item-price-result">€' + resultPrice + '</span><span class="js-cart-page-item-remove cart-page-item-remove"></span></div>');
				});

				totalPrice = totalPrice.toString().indexOf('.') > -1 ? totalPrice.toString() + '0' : totalPrice;

				$totalPrice.text('€' + totalPriceCounter());
				$totalWeight.text(totalWeightCounter() + 'g');
				$window.trigger('totalPriceChanged');

				// Remove item button
				$document.on('click', '.js-cart-page-item-remove', function() {
					var $selfParent = $(this).parent(),
						id = $selfParent.attr('data-id'),
						o = {},
						cs = getLocalstorage(false);

					delete cs.items[id];
					o[cookie] = cs;
					localStorage.setItem('vishnu-cart', JSON.stringify(o));
					updateHeaderCartTemplate();
					updateHeaderCartCounter();
					$totalPrice.text('€' + totalPriceCounter());
					$totalWeight.text(totalWeightCounter() + 'g');
					$window.trigger('totalPriceChanged');
					$selfParent.remove();
					counter--;
					if (counter === 0) {
						$notificationEmpty.addClass('show');
						$cartPageOthers.removeClass('show');
						$cartPageItems.removeClass('show');
					}
				});
				// /Remove item button

				// Item add/remove input counter
				$document.on('click', '.js-item-counter-less', function(event) {
					cartItemCounterUpdate('less', event.target);
					$totalPrice.text('€' + totalPriceCounter());
					$totalWeight.text(totalWeightCounter() + 'g');
					$window.trigger('totalPriceChanged');
				});

				$document.on('click', '.js-item-counter-more', function(event) {
					cartItemCounterUpdate('more', event.target);
					$totalPrice.text('€' + totalPriceCounter());
					$totalWeight.text(totalWeightCounter() + 'g');
					$window.trigger('totalPriceChanged');
				});
				// /Item add/remove input counter
			}
		}
		// /Cart page items list

		// Cart page item counter and price updater
		function cartItemCounterUpdate(state, target) {
			var cartStorage = getLocalstorage(true),
				cookie = window.readCookie('vishnu-products'),
				$item = $(target).parent().parent().parent(),
				id = $item.attr('data-id'),
				$itemCounterAmount = $item.find('.js-item-counter-amount'),
				$itemPriceResult = $item.find('.js-item-price-result'),
				amount = parseInt($itemCounterAmount.text()),
				o = {},
				resultPrice;

			o[cookie] = cartStorage[cookie];

			if (state === 'less' && amount > 1) {
				amount--;
			}

			if (state === 'more') {
				amount++;
			}

			resultPrice = parseFloat(cartStorage[cookie].items[id].price) * amount;
			resultPrice = resultPrice.toString().indexOf('.') > -1 ? resultPrice.toString() + '0' : resultPrice;

			$itemCounterAmount.text(amount);
			$itemPriceResult.text('€' + resultPrice);
			o[cookie].items[id].amount = amount;
			localStorage.setItem('vishnu-cart', JSON.stringify(o));
		}
		// /Cart page item counter and price updater

		// Cart page total price counter
		function totalPriceCounter() {
			var totalPrice = 0,
				cartStorage = getLocalstorage(false).items;

			$.each(cartStorage, function(key) {
				var amount = cartStorage[key].amount,
					resultPrice = parseFloat(cartStorage[key].price) * amount;

				totalPrice += resultPrice;
				resultPrice = resultPrice.toString().indexOf('.') > -1 ? resultPrice.toString() + '0' : resultPrice;
			});

			totalPrice = totalPrice.toString().indexOf('.') > -1 ? totalPrice.toString() + '0' : totalPrice;
			return totalPrice;
		}
		// /Cart page total price counter

		// Cart page total weight counter
		function totalWeightCounter() {
			var totalWeight = 0,
				cartStorage = getLocalstorage(false).items;

			$.each(cartStorage, function(key) {
				var amount = cartStorage[key].amount,
					resultWeight = parseFloat(cartStorage[key].weight) * amount;

				totalWeight += resultWeight;
				resultWeight = resultWeight.toString().indexOf('.') > -1 ? resultWeight.toString() : resultWeight;
			});

			totalWeight = totalWeight.toString().indexOf('.') > -1 ? totalWeight.toString() : totalWeight;
			return totalWeight;
		}
		// /Cart page total weight counter

		// Order button
		var $orderButton = $('.js-order-button'),
			$radioButton = $('.js-radio'),
			postageChosen = false,
			$totalPriceWithPostageWrap = $('.js-total-price-with-postage-wrap'),
			$totalPriceWithPostage = $('.js-total-price-with-postage');

		$radioButton.on('click', function() {
			var weight = $('.js-total-weight').text(),
				$totalPrice = $('.js-total-price'),
				postagePrice,
				itemsPrice,
				localStorage = getLocalstorage(true),
				cookie = window.readCookie('vishnu-products'),
				$self = $(this),
				selfValue = $self.val();

			weight = parseInt(weight.substring(0, weight.length - 1)),
			postagePrice = weight > 5000 ? 6 : 5;
			itemsPrice = parseFloat($totalPrice.text().substring(1));
			postagePrice = selfValue === 'dobierka' ? postagePrice + 1.5 : postagePrice;
			if (selfValue === 'osobne dorucenie' || selfValue === 'odber') {
				postagePrice = 0;
			}
			// console.log('weight', weight);
			// console.log('postagePrice', postagePrice);
			// console.log('itemsPrice', itemsPrice);
			localStorage[cookie].totalPrice = postagePrice + itemsPrice;
			localStorage[cookie].postage = $self.val();
			updateLocalstorage(localStorage);
			$totalPriceWithPostageWrap.removeClass('hide');
			$totalPriceWithPostage.text(function() {
				var totalPrice = parseFloat(getLocalstorage(false).totalPrice);
				return totalPrice.toString().indexOf('.') > -1 ? totalPrice.toString() + '0' : totalPrice;
			});
			// console.log('LS', getLocalstorage(true));
		});

		$orderButton.on('click', function(event) {
			event.preventDefault();

			$radioButton.each(function() {
				var $self = $(this);

				if ($self.is(':checked')) {
					postageChosen = true;
				}
			});

			if (!postageChosen) {
				alert('Vyberte spôsob dopravy');
			} else {
				window.location = '/objednavka';
			}
		});
		// /Order button

		// Free delivery option toggle
		$window.on('totalPriceChanged', function() {
			var $freeDeliveryOption = $('.js-free-delivery-option'),
				totalPrice = parseFloat($('.js-total-price').text().substring(1)),
				chosenPostagePrice = getLocalstorage(false).postage === 'dobierka' ? 1.5 : 0,
				weight = $('.js-total-weight').text(),
				postagePrice;

			$('.js-radio').prop('checked', false);
			$('.js-total-price-with-postage-wrap').addClass('hide');


			weight = parseInt(weight.substring(0, weight.length - 1));
			postagePrice = weight > 5000 ? 6 : 5;

			if (totalPrice >= 25) {
				$freeDeliveryOption.addClass('show');
			} else {
				$freeDeliveryOption.removeClass('show');
			}

			// Update total price with postage
			// // console.log('chosenPostagePrice', chosenPostagePrice);
			$totalPriceWithPostage.text(parseFloat(totalPriceCounter()) + chosenPostagePrice + postagePrice);

		});
		// /Free delivery option toggle

		// Set/read user cookie
		$(document).ready(function() {
			var cartStorage = getLocalstorage(true);

			if (window.readCookie('vishnu-products') && cartStorage[window.readCookie('vishnu-products')]) {
				// nothing
			} else {
				var id = window.makeid(20);
				window.createCookie('vishnu-products', id);
				localStorage.setItem('vishnu-cart', '{"' + id + '":{"items":{}}}');
			}
			updateHeaderCartTemplate();
			updateHeaderCartCounter();

			if (window.location.pathname.indexOf('/kosik') > -1) {
				listCartItems(cartStorage[window.readCookie('vishnu-products')], window.readCookie('vishnu-products'));
			}
		});

		// Header cart
		var $toggleHeaderCart = $('.js-header-cart-toggle'),
			$headerCartItems = $('.js-header-cart-items');

		$toggleHeaderCart.mouseover(function() {
			$headerCartItems.addClass('show');
		});
		$toggleHeaderCart.mouseout(function() {
			$headerCartItems.removeClass('show');
		});
		// /Header cart

		// Order text message
		if (window.location.pathname.indexOf('/objednavka') > -1) {
			var $orderEmailContent = $('.js-order-email-content'),
				$orderSummary = $('.js-order-summary'),
				postageOptions = {
					dobierka: 'Dobierka - €1,50 + poštovné a balné',
					prevod: 'Prevodom na účet - zdarma + poštovné a balné',
					'osobne dorucenie': 'Osobné doručenie Košice, Prešov nad €25 - zdarma',
					odber: 'Osobný odber - zdarma'
				},
				$submitOrderButton = $('.js-submit-order-button'),
				$submitButton = $('.js-submit-button');

			$submitOrderButton.on('click', function() {
				$submitButton.trigger('click');
			});

			if ($orderEmailContent.length > 0) {
				$orderEmailContent.contents()[1].value = generateEmail();
				$orderSummary.html(generateSummary());
			}

			function generateEmail() {
				var s = '<table><tr><th>Produkt</th><th>Množstvo</th><th>Cena za kus</th><th>Cena celkovo</th></tr>',
					cartStorage = getLocalstorage(false).items,
					postage = getLocalstorage(false).postage,
					totalPrice = getLocalstorage(false).totalPrice;

				totalPrice = totalPrice.toString().indexOf('.') > -1 ? totalPrice.toString() + '0' : totalPrice;

				$.each(cartStorage, function(key) {
					var amount = cartStorage[key].amount,
						resultPrice = parseFloat(cartStorage[key].price) * amount;

					resultPrice = resultPrice.toString().indexOf('.') > -1 ? resultPrice.toString() + '0' : resultPrice;
					s += '<tr><td>' + cartStorage[key].name + '</td><td>' + cartStorage[key].amount + '</td><td>' + cartStorage[key].price + '</td><td>€' + resultPrice + '</td></tr>';
				});

				s += '';
				s += '<tr><td colspan="4">Poštovné : ' + postageOptions[postage] + '</tr>';
				s += '<tr><td colspan="4">Celková cena : €' + totalPrice + '</tr></table>';
				return s;
			}

			function generateSummary() {
				var s = '<table><tr><th>Produkt</th><th>Množstvo</th><th>Cena za kus</th><th>Cena celkovo</th></tr>',
					cartStorage = getLocalstorage(false).items,
					postage = getLocalstorage(false).postage,
					totalPrice = getLocalstorage(false).totalPrice;

				totalPrice = totalPrice.toString().indexOf('.') > -1 ? totalPrice.toString() + '0' : totalPrice;

				$.each(cartStorage, function(key) {
					var amount = cartStorage[key].amount,
						resultPrice = parseFloat(cartStorage[key].price) * amount;

					resultPrice = resultPrice.toString().indexOf('.') > -1 ? resultPrice.toString() + '0' : resultPrice;
					s += '<tr><td>' + cartStorage[key].name + '</td><td>' + cartStorage[key].amount + '</td><td>' + cartStorage[key].price + '</td><td>€' + resultPrice + '</td></tr>';
				});

				s += '';
				s += '<tr><td colspan="4">Poštovné : ' + postageOptions[postage] + '</tr>';
				s += '<tr><td colspan="4">Celková cena : €' + totalPrice + '</tr></table>';
				return s;
			}

			// Clear cart when success
			if ($('#respond .success').length > 0) {
				updateLocalstorage('{}');
			}
			// /Clear cart when success
		}
		// /Order text message

		/**
		 * Update localstorage
		 * @param {Object} data
		 */
		function updateLocalstorage(data) {
			// console.log('updateLocalstorage...');
			localStorage.setItem('vishnu-cart', JSON.stringify(data));
		}

		/**
		 * Get localstorage
		 * @param {boolean} full
		 * @returns {Object}
		 */
		function getLocalstorage(full) {
			return full ? JSON.parse(localStorage.getItem('vishnu-cart')) : JSON.parse(localStorage.getItem('vishnu-cart'))[window.readCookie('vishnu-products')];
		}

		// @todo remove this assignment
		window.x = getLocalstorage;
	})();
// /Cart

	// Scroll top
	var $scrollTop = $('.js-scroll-top');

	$scrollTop.on('click', function() {
		$htmlBody.animate({scrollTop : 0},800);
		return false;
	});
	// /Scroll top
})();
